import React from 'react'

const Para = () => {
    const paraStyle = {
        width: "100%",
        minHeight: "30vh",
        margin: "20px 0px",
        color: "#333333",
        fontSize: "1rem",
        fontFamily: "Helvetica",
        fontWeight: "medium",
        letterSpacing: ".02rem"
    }
    return (
        <>
            <div style={paraStyle}>
                <h3>For other contaminants not listed, specific monitoring devices and pre-work planning will be
                    required to ensure safe entry. </h3>
                <h3 style={{ marginBottom: "20px" }}>All workers are prohibited from entering a confined space where a
                    safe atmosphere is unable to be achieved. </h3>
                <h3>The atmosphere in a confined space must be continuously monitored while workers are in the space</h3>
            </div>

        </>
    )
}
export default Para;
