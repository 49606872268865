import React, { useState } from 'react'
import "./Switch.css";
import safety_button_on from "../../../images/safety/safety_button_on.svg"
import safety_button_off from "../../../images/safety/safety_button_off.svg"

const Switch = (props) => {
    const [toggle, setToggle] = useState();
    const onOffHandler = () => {
        setToggle(!toggle);
    }
    return (
        <>
            <div className={props.className}>
                <div className="switch_head">
                    <p>{props.head}</p>
                </div>
                <div className="on_off_images">
                    {toggle ? (
                        <>
                            <p>yes</p>
                            <div className="on_image">
                                <img onClick={onOffHandler} src={safety_button_on} alt="switch img" />
                            </div>
                        </>
                    ) :
                        (
                            <>
                                <p>no</p>
                                <div className="off_image">
                                    <img onClick={onOffHandler} src={safety_button_off} alt="switch img" />
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </>
    )
}
export default Switch;