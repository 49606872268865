import React, { useState } from 'react'
import checks from "../../../images/safety/checks.svg"
import blank_checks from "../../../images/safety/blank_checks.svg"
const Gas_Check = (props) => {
    const [state, setState] = useState(true);
    const allHandler = () => {
        setState(!state);
    }
    return (
        <div className={props.className} id={state ? ("") : ("check_background")}>
            <div className="check_gas_name">{props.name}</div>
            <div onClick={allHandler}>
                {
                    state ? (<img src={blank_checks} alt="gas img" />) : (<img src={checks} alt="gas img" />)
                }
            </div>
        </div>
    )
}
export default Gas_Check;