import React, { useState } from 'react'
import "./Gas.css"
import InputField from '../InputField/InputField'
import Gas_Check from "./Gas_Check"

const Gas = (props) => {
    const [para, setPara] = useState();
    return (
        <>
            <section className="gas">
                <div className="gas_name">
                    <div className="gas_name_left">
                        <h3>{props.head_1}</h3>
                        <p>atmospheric test</p>
                    </div>
                    <div className="gas_name_right">
                        <h3>{props.head_2}</h3>
                        <p>permissible entry</p>
                    </div>
                </div>
                <div className="gas_checks">
                    <Gas_Check className="gas_checks_left" name="tested top of space" />
                    <Gas_Check className="gas_checks_center" name="tested middle of space" />
                    <Gas_Check className="gas_checks_right" name="tested bottom" />
                </div>
                <div className="gas_para">
                    <p>peak reading</p>
                    <InputField idName="input_para_id" className="input_para" type="text" value={para} change={setPara} />
                </div>
            </section>
        </>
    )
}
export default Gas;